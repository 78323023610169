export default {
  buildMode: "dev",
  diaryApiBaseUrl: "https://testdiaryapi.learnbody.co.kr/",
  diaryReportApiBaseUrl:
    "https://linux-diary-report-api-slot.azurewebsites.net/",
  guideApiBaseUrl: "https://testguideapi.learnbody.co.kr/",
  marketApiBaseUrl: "https://testmarketapi.learnbody.co.kr/",
  marketStorageUrl:
    "https://learnbodykr.blob.core.windows.net/test-diary-images/",
  cdnUrl: "https://learnbodykr.blob.core.windows.net/cdn/",
  storageDIR: "test-diary-user-photo",
  shopByBaseUrl: "https://alpha-api.e-ncp.com/",
  shopByClientId: "47fzWj2ESmKB9fDCwbbZoA==",
  communityDomain: "https://testcommunityapp.learnbody.co.kr/",
  marketDomain: "https://testmarket.learnbody.co.kr",
  guideDomain: "https://testguide.azurewebsites.net",
  fitnessApiBaseUrl: "https://testfitnessapi.learnbody.co.kr/",
  // fitnessApiBaseUrl: "https://testfitnessapi3.learnbody.co.kr/",
};
